<template lang="">
  <div
    v-if="
      user.permissions.indexOf(permissionsAll.product_list) >= 0 ||
        user.person.types_person.id === 3
    "
  >
    <CardProducts />
  </div>
</template>
<script>
import { CardProducts } from "../../components/products";
import permissions from "../../helpers/permissions";
import { mapState } from "vuex";
export default {
  name: "ProductsView",
  components: {
    CardProducts,
  },
  data() {
    return {
      permissionsAll: permissions,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>
